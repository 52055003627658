import './App.css';

function App() {
  return (
    <div className="container">
      <div className="brands">
        <a className="brand" href="https://indigostudios.com">
          <img src="/images/IS.png" alt="Indigo Studios"/>
        </a>
        <a className="brand" href="https://goodbit.tv">
          <img src="/images/GB.png" alt="Goodbit"/>
        </a>
      </div>
      <div className="copyright">Indigo Studios/Goodbit. Copyright 2024</div>
    </div>
  );
}

export default App;
